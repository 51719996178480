import React, { useEffect } from "react";

// Mui
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

// Firebase
import { getRequest } from "../../../actions/requests";

const PopupDialog = () => {
    // States
    const [width, setWidth] = React.useState(window.innerWidth);
    const [open, setOpen] = React.useState(false);
    const [popupImageUrl, setPopupImageUrl] = React.useState("");

    const handleClose = () => {
        setOpen(false);
    };

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const fetchActivePopup = async () => {
        const payload = await getRequest("popups/activePopup");

        if (payload.data && payload.data.activePopup) {
            if (width >= 768) setPopupImageUrl(payload.data.activePopup.webUrl);
            else setPopupImageUrl(payload.data.activePopup.mobileUrl);
        }
    };

    useEffect(() => {
        fetchActivePopup();
    }, [width]);

    useEffect(() => {
        if (popupImageUrl) setOpen(true);
    }, [popupImageUrl]);

    return (
        <Dialog
            PaperProps={
                {
                    // style: {
                    //     width: "70vw",
                    //     height: "50vh",
                    // },
                }
            }
            fullWidth={false}
            maxWidth="xl"
            open={open}
            onClose={handleClose}
        >
            <DialogContent style={{ margin: 0, padding: 0 }}>
                <IconButton
                    style={{ position: "absolute", right: 10, top: 10, backgroundColor: "rgba(0, 0, 0, 0.7)", zIndex: 99999 }}
                    onClick={handleClose}
                >
                    <CloseIcon style={{ fill: "white", height: 40, width: 40 }} />
                </IconButton>
                <div className="centered__parent">
                    <img src={popupImageUrl} style={{ width: "92%" }} alt="" />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default PopupDialog;
