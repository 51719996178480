import React, { useContext } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { getRequest } from "../../../actions/requests";
import { useSnackbar } from "notistack";
import { auth } from "../../../configs/firebase";

// Mui
import Grid from "@material-ui/core/Grid";

// draft js
import draftToHtml from "draftjs-to-html";
import parse from "html-react-parser";

// Assets
import pdfPlaceholder from "../../../assets/shared/pdfPlaceholder.jpg";

// custom components
import VideoThumbnail from "./videoThumbnail";

// utils
import { doesPhaseHasDocument, downloadFile, onToggleFavorite } from "./utils";
import { UserContext } from "../../../reducer/userContext";

// redux
import useSlice from "../../../hooks/useSlice";

const PhaseItem = ({ phase, items, onClick, workoutId }) => {
    const hist = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { userEnteredUnscramlingEmail, user } = useContext(UserContext);
    const userSlice = useSlice({ sliceName: "users" });

    /*
     * Checks for release date. If phase is not yet released then we show a message stating so.
     * Otherwise, we show the content of the phase
     * */
    const getPhaseContent = () => {
        if (phase.release) {
            const releaseDate = new Date(phase.release._value);

            if (releaseDate > Date.now())
                return (
                    <>
                        <h3 className="workout__unavailable">
                            <sup>*</sup>Le contenu exclusif de cette phase sera disponible bientôt... ({releaseDate.toLocaleDateString()})
                        </h3>
                    </>
                );
        }
        return (
            <>
                <div className="workout__phase__items">
                    {/* videos grid */}
                    <Grid container spacing={1}>
                        {items &&
                            items
                                .filter(item => {
                                    if (!item.release) return true;
                                    const release = new Date(item.release._value);
                                    return Date.now() - release > 0;
                                })
                                .map(
                                    (item, index) =>
                                        item &&
                                        //isItemReleased(item) &&
                                        item.hasOwnProperty("difficulty") && (
                                            <Grid item xs={12} sm={6} md={3} key={index}>
                                                <VideoThumbnail
                                                    item={item}
                                                    onClick={() => onClick(item.index)}
                                                    workoutId={workoutId}
                                                    initialValue={(() => {
                                                        if (!user) return false;
                                                        if (!user.favoriteVideos) return false;
                                                        if (!user.favoriteVideos[workoutId]) return false;
                                                        return user.favoriteVideos[workoutId].findIndex(v => v === item.id) > -1;
                                                    })()}
                                                    onToggleFavorite={() => {
                                                        const { isFavorite, favs } = onToggleFavorite(user, workoutId, item);
                                                        userSlice.actionCreators.updateFn({
                                                            ...user,
                                                            favoriteVideos: favs,
                                                            updateCurrentUser: true,
                                                            showSuccessSnackbar: false,
                                                        });
                                                        return isFavorite;
                                                    }}
                                                />
                                                {parse(draftToHtml(JSON.parse(item.description)))}
                                            </Grid>
                                        )
                                )}
                    </Grid>
                </div>

                {/* item documents grid*/}
                {doesPhaseHasDocument(items) && (
                    <>
                        <h3 className="workout__title">Documents</h3>
                        <Grid container>
                            {items &&
                                items
                                    .filter(item => {
                                        if (userEnteredUnscramlingEmail) {
                                            return !item.title.includes("alimentaire");
                                        }
                                        return true;
                                    })
                                    .map(
                                        (item, index) =>
                                            !item.hasOwnProperty("difficulty") && (
                                                <Grid item xs={12} sm={4} md={3} key={index}>
                                                    {item.thumbnailImage ? (
                                                        <div style={{ display: "block" }}>
                                                            <img
                                                                onClick={() => downloadFile(item.url)}
                                                                style={{
                                                                    objectFit: "cover",
                                                                    aspectRatio: "16/9",
                                                                    maxWidth: "100%",
                                                                    border: "7px solid white",
                                                                }}
                                                                src={item.thumbnailImage}
                                                                alt="pdf"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <img
                                                            onClick={() => downloadFile(item.url)}
                                                            className="workout__doc__thumbnail"
                                                            src={pdfPlaceholder}
                                                            alt="pdf"
                                                        />
                                                    )}
                                                    <span className="workout__item__title">{item.title}</span>
                                                </Grid>
                                            )
                                    )}
                        </Grid>
                    </>
                )}
            </>
        );
    };

    const checkUnscrambled = async () => {
        if (!auth.currentUser || (auth.currentUser && !user.hasVideoAccess)) {
            const payload = await getRequest("unscramblings/isUnscrambled");
            if (!userEnteredUnscramlingEmail || !payload.data || !payload.data.isUnscrambled) {
                enqueueSnackbar("Accès refusé", { variant: "error" });
                hist.push("/portail/login");
            }
        }
    };

    // check if unscrambled
    useEffect(() => {
        checkUnscrambled();
    }, []);

    return (
        <>
            <h3 className="workout__phase__title">{phase.title}</h3>
            <span className="workout__phase__description">{phase.description}</span>
            <div className="workout__phase__content">{getPhaseContent()}</div>
            <div className="workout__divider">
                <span></span>
            </div>
        </>
    );
};

PhaseItem.propTypes = {
    phase: PropTypes.object.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClick: PropTypes.func.isRequired,
};

export default PhaseItem;
